export const addLandCoveringUnitVisuals = [
  {
    name: "Demo",
    markdown: `![Add land covering ownership unit GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const addFloorAreaUnitVisuals = [
  {
    name: "Demo",
    markdown: `![Add floor area ownership unit GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const addOwnershipUnit = {
  order: 24,
  name: "Add an ownership unit",
  keywords: "condominium co-op coop",
  subcategory: "Properties",
  markdown: `# Add an ownership unit

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  {% inlineRouterLink %}Condominium{% /inlineRouterLink %} and {% inlineRouterLink %}co-op{% /inlineRouterLink %} ownership structures are sometimes used to allow discrete parts of {% inlineRouterLink %}buildings{% /inlineRouterLink %} to be controlled by different parties. In Tower Hunt, you can represent these types of structures on the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}. Doing so allows you to track the {% inlineRouterLink %}investments{% /inlineRouterLink %} and {% inlineRouterLink %}players{% /inlineRouterLink %} involved with each {% inlineAppIcon iconName="cube" /%} ownership unit, providing richer context.

  ## What to expect

  Like other property rights, condominiums and co-ops are most useful when they link other {% inlineRouterLink %}datapoints{% /inlineRouterLink %} together. After adding an {% inlineAppIcon iconName="cube" /%} ownership unit, you should enrich it with any of the following {% inlineAppIcon iconName="private" /%}{% inlineRouterLink articleId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} datapoints that you know:

  - {% inlineRouterLink %}Milestone dates{% /inlineRouterLink %} govern the status of the unit and when it exists
  - {% inlineRouterLink %}Investments{% /inlineRouterLink %} provide essential information about {% inlineRouterLink %}valuation{% /inlineRouterLink %} and involved {% inlineRouterLink %}players{% /inlineRouterLink %}

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Land coverings" %}land covering{% /inlineRouterLink %} or {% inlineRouterLink %}floor area{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="addLandCoveringCondo" /%} for a condominium unit or {% inlinePropertyDiagramIcon name="addLandCoveringCoop" /%} for a co-op unit.
  4. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" sectionName="Placing new datapoints on the diagram" %}**Select**{% /inlineRouterLink %} one or more land coverings or floor areas to be controlled by the unit. Selecting multiple parts will cause the {% inlineAppIcon iconName="cube" /%} ownership unit to link to all of those parts.
  5. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. A new {% inlineAppIcon iconName="cube" /%} ownership unit appears in the selected diagram parts.
  {% /tablessContainer %}

  ## On a floor area

  Applying {% inlineAppIcon iconName="cube" /%} ownership units to {% inlineRouterLink %}floor areas{% /inlineRouterLink %} is the more common situation. This approach allows for the familiar residential condo structure, as well as the {% inlineRouterLink %}property-use{% /inlineRouterLink %}-based separation seen in mixed-use commercial properties.

  {% tabbedContainer tabs=$addFloorAreaUnitVisuals /%}

  ## On a land covering

  Placing an {% inlineAppIcon iconName="cube" /%} ownership unit on a land covering makes sense when used in combination with {% inlineRouterLink articleId="group-land-coverings" %}land covering groupings{% /inlineRouterLink %}. In this situation the land covering in the diagram usually represents part of a larger physical structure. As such, the land covering is often acting like a very large {% inlineRouterLink %}floor area{% /inlineRouterLink %} with a specific {% inlineRouterLink %}property use{% /inlineRouterLink %}.

  {% tabbedContainer tabs=$addLandCoveringUnitVisuals /%}`,
};
